import App from "../App";
import { Login } from "../pages/Auth";
import Auth from "../pages/Auth/Auth";
import {
  BuildingStorefrontIcon,
  HomeIcon,
  TableCellsIcon,
  UserIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline";
import User from "../pages/User";
import Userlist from "../pages/User/UserList";
import UserCreate from "../pages/User/UserCreate";

import Company from "../pages/Company";
import CompanyList from "../pages/Company/CompanyList";
import CompanyCreate from "../pages/Company/CompanyCreate";
import ProductList from "../pages/Product/ProductList";
import ProductCreate from "../pages/Product/ProductCreate";
import Product from "../pages/Product";
import {
  IconActivity,
  IconBuildingStore,
  IconBuildingWarehouse,
  IconCubeSend,
  IconListCheck,
  IconMeteor,
  IconPackages,
  IconReportMoney,
} from "@tabler/icons-react";
import ProductCategoryList from "../pages/Product/Category/CategoryList";
import ProductCategoryCreate from "../pages/Product/Category/CategoryCreate";
import ProductSubCategoryList from "../pages/Product/SubCategory/SubCategoryList";
import ProductSubCategoryCreate from "../pages/Product/SubCategory/SubCategoryCreate";
import ShowCaseCreate from "../pages/Showcase/ShowcaseCreate";
import ShowCase from "../pages/Showcase";
import ShowCaseList from "../pages/Showcase/ShowcaseList";
import ShowcaseTransactions from "../pages/Showcase/ShowcaseTransactions";
import ProductBrandList from "../pages/Product/Brand/BrandList";
import ProductBrandCreate from "../pages/Product/Brand/BrandCreate";
import ProductCardTypeList from "../pages/Product/CardType/CardTypeList";
import ProductCardTypeCreate from "../pages/Product/CardType/CardTypeCreate";
import NotFound from "../pages/Error/404";
import StorageList from "../pages/Storage/StorageList";
import StorageCreate from "../pages/Storage/StorageCreate";
import Storage from "../pages/Storage";
import StorageCategoryList from "../pages/Storage/Category/CategoryList";
import StorageCategoryCreate from "../pages/Storage/Category/CategoryCreate";
import SalesArea from "../pages/SalesArea";
import MainAreaList from "../pages/SalesArea/MainSalesArea/MainSalesAreaList";
import MainAreaCreate from "../pages/SalesArea/MainSalesArea/MainSalesAreaCreate";
import MainSalesAreaList from "../pages/SalesArea/MainSalesArea/MainSalesAreaList";
import MainSalesAreaCreate from "../pages/SalesArea/MainSalesArea/MainSalesAreaCreate";
import SubSalesAreaList from "../pages/SalesArea/SubSalesArea/SubSalesAreaList";
import SubSalesAreaCreate from "../pages/SalesArea/SubSalesArea/SubSalesAreaCreate";
import StorageTransactions from "../pages/Storage/StorageTransaction";
import StorageProductTransactions from "../pages/Storage/StorageProductTransactions";
import SaleSpotCreate from "../pages/SaleSpot/SaleSpotCreate";
import SaleSpotList from "../pages/SaleSpot/SaleSpotList";
import SaleSpot from "../pages/SaleSpot";
import SaleSpotCategoryList from "../pages/SaleSpot/Category/CategoryList";
import SaleSpotCategoryCreate from "../pages/SaleSpot/Category/CategoryCreate";
import SaleSpotManagement from "../pages/SaleSpot/SaleSpotManagement";
import UserActionList from "../pages/UserActions/UserActionList";
import DeviceActionList from "../pages/DeviceActions/DeviceActionList";
import StorageOrders from "../pages/Storage/StorageOrders";
import StorageOrderDetail from "../pages/Storage/StorageOrderDetail";
import SaleSpotShowcaseList from "../pages/SaleSpot/SaleSpotShowcaseList";
import Delivery from "../pages/Delivery";
import DeliveryList from "../pages/Delivery/DeliveryList";
import DeliveryCreate from "../pages/Delivery/DeliveryCreate";
import DeliveryTasks from "../pages/DeliveryTasks";
import DeliveryTaskList from "../pages/DeliveryTasks/DeliveryTasks";
import DeliveryTaskDetail from "../pages/DeliveryTasks/DeliveryTaskDetail";
import DeliveryTaskSaleSpotDetail from "../pages/DeliveryTasks/DeliveryTaskSaleSpotDetail";
import Profile from "../pages/Profile/Profile";

import QueueList from "@heroicons/react/24/outline/QueueListIcon";
import Transactions from "../pages/Transactions/Transaction";
import TransactionDetail from "../pages/Transactions/TransactionDetail";
import Transaction from "../pages/Transactions";
import CampaignList from "../pages/Campaign/CampaignList";
import CampaignCreate from "../pages/Campaign/CampaignCreate";
import Campaign from "../pages/Campaign";
import RegisteredUserList from "../pages/User/RegisteredList";
import ProductReport from "../pages/ProductReport";

import { default as ProductReportList } from "../pages/ProductReport/ProductReport";
import PurchaseSaleList from "../pages/Product/PurchaseSale/PurchaseSaleList";
const routes = [
  {
    name: "auth",
    path: "/auth",
    element: Auth,
    isPrivate: false,
    role: "public",
    subRoutes: [
      {
        name: "Login",
        path: "login",
        element: Login,
        role: "public",
      },
      {
        name: "",
        path: "",
        to: "/auth/login",
        isVisible: false,
        isNavigate: true,
        role: "public",
      },
    ],
  },
  {
    name: "Dashboard",
    path: "/",
    icon: HomeIcon,
    isPrivate: true,
    role: "public",
    element: App,
  },
  {
    name: "Profile",
    path: "profile",
    element: Profile,
    isVisible: false,
    isPrivate: true,
  },
  {
    name: "User",
    path: "/user",
    icon: UserIcon,
    isPrivate: true,
    element: User,
    subRoutes: [
      {
        name: "List",
        path: "list",
        element: Userlist,
        isVisible: false,
      },
      {
        name: "RegisteredList",
        path: "registered-list",
        element: RegisteredUserList,
        isVisible: true,
      },
      {
        name: "Create",
        path: "create",
        element: UserCreate,
        isVisible: false,
      },
      {
        name: "Edit",
        path: "edit/:id",
        element: UserCreate,
        isVisible: false,
      },
      {
        name: "",
        path: "",
        to: "/user/list",
        isVisible: false,
        isNavigate: true,
      },
    ],
  },
  {
    name: "Company",
    path: "/company",
    icon: BuildingStorefrontIcon,
    isPrivate: true,
    element: Company,
    subRoutes: [
      {
        name: "List",
        path: "list",
        element: CompanyList,
        isVisible: false,
      },
      {
        name: "Create",
        path: "create",
        element: CompanyCreate,
        isVisible: false,
      },
      {
        name: "Edit",
        path: "edit/:id",
        element: CompanyCreate,
        isVisible: false,
      },
      {
        name: "",
        path: "",
        to: "/company/list",
        isVisible: false,
        isNavigate: true,
      },
    ],
  },
  {
    name: "Product",
    path: "/product",
    isPrivate: true,
    icon: IconPackages,
    element: Product,
    subRoutes: [
      {
        name: "List",
        path: "list",
        element: ProductList,
        isVisible: true,
      },
      {
        name: "Create",
        path: "create",
        element: ProductCreate,
        isVisible: false,
      },
      {
        name: "Edit",
        path: "edit/:id",
        element: ProductCreate,
        isVisible: false,
      },
      {
        name: "BrandList",
        path: "brand/list",
        element: ProductBrandList,
        isVisible: true,
      },
      {
        name: "BrandCreate",
        path: "brand/create",
        element: ProductBrandCreate,
        isVisible: false,
      },
      {
        name: "BrandEdit",
        path: "brand/edit/:id",
        element: ProductBrandCreate,
        isVisible: false,
      },
      {
        name: "CardTypeList",
        path: "card-type/list",
        element: ProductCardTypeList,
        isVisible: true,
      },
      {
        name: "CardTypeCreate",
        path: "card-type/create",
        element: ProductCardTypeCreate,
        isVisible: false,
      },
      {
        name: "CardTypeEdit",
        path: "card-type/edit/:id",
        element: ProductCardTypeCreate,
        isVisible: false,
      },
      {
        name: "CategoryList",
        path: "category/list",
        element: ProductCategoryList,
        isVisible: true,
      },
      {
        name: "CategoryCreate",
        path: "category/create",
        element: ProductCategoryCreate,
        isVisible: false,
      },
      {
        name: "CategoryEdit",
        path: "category/edit/:id",
        element: ProductCategoryCreate,
        isVisible: false,
      },
      {
        name: "SubCategoryList",
        path: "sub-category/list",
        element: ProductSubCategoryList,
        isVisible: true,
      },
      {
        name: "SubCategoryCreate",
        path: "sub-category/create",
        element: ProductSubCategoryCreate,
        isVisible: false,
      },
      {
        name: "SubCategoryEdit",
        path: "sub-category/edit/:id",
        element: ProductSubCategoryCreate,
        isVisible: false,
      },

      {
        name: "PurchaseSaleList",
        path: "purchase-sale/list",
        element: PurchaseSaleList,
        isVisible: true,
      },

      {
        name: "",
        path: "",
        to: "/product/list",
        isVisible: false,
        isNavigate: true,
      },
    ],
  },
  {
    name: "Sales",
    path: "/sale-area",
    isPrivate: true,
    icon: Square3Stack3DIcon,
    element: SalesArea,
    subRoutes: [
      {
        name: "MainSalesAreaList",
        path: "main-sales-area/list",
        element: MainSalesAreaList,
        isVisible: true,
      },
      {
        name: "MainSalesAreaCreate",
        path: "main-sales-area/create",
        element: MainSalesAreaCreate,
        isVisible: false,
      },
      {
        name: "MainSalesAreaEdit",
        path: "main-sales-area/edit/:id",
        element: MainSalesAreaCreate,
        isVisible: false,
      },
      {
        name: "SubSalesAreaList",
        path: "sub-sales-area/list",
        element: SubSalesAreaList,
        isVisible: true,
      },
      {
        name: "SubSalesAreaCreate",
        path: "sub-sales-area/create",
        element: SubSalesAreaCreate,
        isVisible: false,
      },
      {
        name: "SubSalesAreaEdit",
        path: "sub-sales-area/edit/:id",
        element: SubSalesAreaCreate,
        isVisible: false,
      },
      {
        name: "",
        path: "",
        to: "/sales/list",
        isVisible: false,
        isNavigate: true,
      },
    ],
  },

  {
    name: "Storage",
    path: "/storage",
    isPrivate: true,
    icon: IconBuildingWarehouse,
    element: Storage,
    role: "storage:user",
    subRoutes: [
      {
        name: "List",
        path: "list",
        element: StorageList,
        isVisible: true,
        role: "storage:user",
      },
      {
        name: "Create",
        path: "create",
        element: StorageCreate,
        isVisible: false,
      },
      {
        name: "Orders",
        path: "orders",
        element: StorageOrders,
        isVisible: true,
        role: "storage:user",
      },
      {
        name: "OrderDetail",
        path: "order/detail/:id",
        element: StorageOrderDetail,
        isVisible: false,
        role: "storage:user",
      },
      {
        name: "Edit",
        path: "edit/:id",
        element: StorageCreate,
        isVisible: false,
      },
      {
        name: "CategoryList",
        path: "category/list",
        element: StorageCategoryList,
        isVisible: true,
      },
      {
        name: "Transactions",
        path: "transactions/:id",
        element: StorageTransactions,
        isVisible: false,
        role: "storage:user",
      },
      {
        name: "ProductTransactions",
        path: "product-transactions/:id",
        element: StorageProductTransactions,
        isVisible: false,
        role: "storage:user",
      },
      {
        name: "CategoryCreate",
        path: "category/create",
        element: StorageCategoryCreate,
        isVisible: false,
      },
      {
        name: "CategoryEdit",
        path: "category/edit/:id",
        element: StorageCategoryCreate,
        isVisible: false,
      },

      {
        name: "",
        path: "",
        to: "/storage/list",
        role: "storage:user",
        isVisible: false,
        isNavigate: true,
      },
    ],
  },
  {
    name: "ShowCase",
    path: "/showcase",
    isPrivate: true,
    icon: TableCellsIcon,
    element: ShowCase,
    subRoutes: [
      {
        name: "Create",
        path: "create",
        element: ShowCaseCreate,
        isVisible: false,
      },
      {
        name: "List",
        path: "list",
        element: ShowCaseList,
        isVisible: false,
      },
      {
        name: "Transactions",
        path: "transactions/:id",
        element: ShowcaseTransactions,
        isVisible: false,
      },
      {
        name: "",
        path: "",
        to: "/showcase/list",
        isVisible: false,
        isNavigate: true,
      },
    ],
  },
  {
    name: "SaleSpot",
    path: "/salespot",
    isPrivate: true,
    icon: IconBuildingStore,
    element: SaleSpot,
    subRoutes: [
      {
        name: "Management",
        path: "management",
        element: SaleSpotManagement,
        isVisible: true,
      },
      {
        name: "List",
        path: "list",
        element: SaleSpotList,
        isVisible: true,
      },
      {
        name: "Create",
        path: "create",
        element: SaleSpotCreate,
        isVisible: false,
      },
      {
        name: "Edit",
        path: "edit/:id",
        element: SaleSpotCreate,
        isVisible: false,
      },

      {
        name: "CategoryList",
        path: "category/list",
        element: SaleSpotCategoryList,
        isVisible: true,
      },
      {
        name: "CategoryCreate",
        path: "category/create",
        element: SaleSpotCategoryCreate,
        isVisible: false,
      },
      {
        name: "Transactions",
        path: "transactions/:sale_spot_id",
        element: SaleSpotShowcaseList,
        isVisible: false,
      },
      {
        name: "CategoryEdit",
        path: "category/edit/:id",
        element: SaleSpotCategoryCreate,
        isVisible: false,
      },

      {
        name: "",
        path: "",
        to: "/salespot/list",
        isVisible: false,
        isNavigate: true,
      },
    ],
  },
  {
    name: "Delivery",
    path: "/delivery",
    isPrivate: true,
    icon: IconCubeSend,
    element: Delivery,
    role: "storage:user",
    subRoutes: [
      {
        name: "List",
        path: "list",
        element: DeliveryList,
        role: "storage:user",
        isVisible: true,
      },
      {
        name: "Create",
        path: "create",
        element: DeliveryCreate,
        isVisible: false,
      },

      {
        name: "",
        path: "",
        to: "/delivery/list",
        isVisible: false,
        isNavigate: true,
      },
    ],
  },
  {
    name: "UserActions",
    path: "/action-logs",
    isPrivate: true,
    icon: IconActivity,
    element: UserActionList,
  },
  {
    name: "DeviceActions",
    path: "/device-action-logs",
    isPrivate: true,
    icon: IconActivity,
    element: DeviceActionList,
  },
  {
    name: "Transactions",
    path: "/transactions",
    isPrivate: true,
    icon: QueueList,
    element: Transaction,
    subRoutes: [
      {
        name: "TransactionList",
        path: "list",
        element: Transactions,
        isVisible: false,
      },
      {
        name: "TransactionDetail",
        path: "detail/:id",
        element: TransactionDetail,
        isVisible: false,
      },
      {
        name: "",
        path: "",
        to: "/transactions/list",

        isVisible: false,
        isNavigate: true,
      },
    ],
  },

  {
    name: "DeliveryTasks",
    path: "/delivery-tasks",
    isPrivate: true,
    icon: IconListCheck,
    role: "delivery:user",
    element: DeliveryTasks,
    subRoutes: [
      {
        name: "TaskList",
        path: "list",
        element: DeliveryTaskList,
        isVisible: true,
        role: "delivery:user",
      },
      {
        name: "TaskDetail",
        path: "detail/:id",
        element: DeliveryTaskDetail,
        isVisible: false,
        role: "delivery:user",
      },
      {
        name: "TaskSaleSpotDetail",
        path: "detail/:task_id/:id",
        element: DeliveryTaskSaleSpotDetail,
        isVisible: false,
        role: "delivery:user",
      },
      {
        name: "",
        path: "",
        to: "/delivery-tasks/list",
        isVisible: false,
        isNavigate: true,
        role: "delivery:user",
      },
    ],
  },
  {
    name: "ProductReport",
    path: "/product-report",
    isPrivate: true,
    icon: IconReportMoney,
    element: ProductReport,
    subRoutes: [
      {
        name: "",
        path: "",
        element: ProductReportList,
      },
    ],
  },
  {
    name: "Campaign",
    path: "/campaign",
    isPrivate: true,
    icon: IconMeteor,
    element: Campaign,
    subRoutes: [
      {
        name: "List",
        path: "list",
        element: CampaignList,
        isVisible: true,
      },
      {
        name: "Create",
        path: "create",
        element: CampaignCreate,
        isVisible: false,
      },
      {
        name: "Edit",
        path: "edit/:id",
        element: CampaignCreate,
        isVisible: false,
      },

      {
        name: "",
        path: "",
        to: "/campaign/list",
        isVisible: false,
        isNavigate: true,
      },
    ],
  },

  {
    path: "*",
    element: NotFound,
    name: "404",
    isVisible: false,
  },
];

export default routes;
