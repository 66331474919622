import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import services from "../../services";
import { useForm, useWatch } from "react-hook-form";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import BarLoading from "../../components/Loading/BarLoading";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { Group, Image, Input, Text, rem } from "@mantine/core";
import { IconCheck, IconPhoto, IconX } from "@tabler/icons-react";
import { Tooltip } from "@mantine/core";
import { Switch } from "@mantine/core";

import SaveButton from "../../components/Buttons/SaveButton";
import {
  SaleSpotCreateValidations,
  SaleSpotUpdateValidations,
} from "./validations";
import { setKey, setLanguage, setRegion, setLocationType } from "react-geocode";
import { yupResolver } from "@hookform/resolvers/yup";
import { config } from "../../config";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconUpload } from "@tabler/icons-react";
import { compose, withProps } from "recompose";
import {
  GoogleMap,
  Polygon,
  withGoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import Duplicator from "../../components/Duplicator/Duplicator";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomMultiSelect from "../../components/CustomSelect/CustomMultiSelect";
const mapStyles = require("../../static/mapStyles.json");
const DefaultZoom = 10;
const DefaultLocation = { lat: 41.015137, lng: 28.97953 };
setKey(config.MAP_API_KEY);
setLanguage("tr");
setRegion("tr_TR");
setLocationType("ROOFTOP");

const PolygonDrawer = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCBM4uTkUhiTpZYBUFxR5NZ2ayD6S-PrmI",
    loadingElement: <div className="w-full" style={{ height: `100%` }} />,
    containerElement: <div className="w-full" style={{ height: `400px` }} />,
    mapElement: <div className="w-full" style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(
  ({
    value,
    drawingManagerRef,
    isActive = false,
    coordinates,
    isUpdate,
    googleMapRef,
    currentMarker,
    center,
    setCurrentMarker,
    ...props
  }) => {
    let polygonRef = useRef(null);

    return (
      <GoogleMap
        ref={googleMapRef}
        defaultZoom={14}
        defaultOptions={mapStyles}
        onClick={(location) => {
          setCurrentMarker(location.latLng.lat(), location.latLng.lng());
        }}
        center={center || DefaultLocation}
      >
        <DrawingManager
          ref={drawingManagerRef}
          defaultDrawingMode={window.google.maps.drawing.OverlayType.POLYGON}
          onPolygonComplete={(polygon) => {
            props.setCoordinates(polygon);
          }}
          onMapOverlayComplete={() => console.log("OVERLAY INTILIZED")}
          options={{
            drawingControl: isActive,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
            },
            defaultLocation: coordinates,
            polygonOptions: {
              fillColor: "rgba(217, 119, 6, 0.7)",
              editable: true,
            },
            drawingMode: isActive
              ? window.google.maps.drawing.OverlayType.POLYGON
              : null,
          }}
          defaultOptions={{
            drawingControl: false,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
            },

            polygonOptions: {
              fillColor: "rgba(217, 119, 6, 0.7)",
              editable: true,
            },
          }}
        />
        {currentMarker && (
          <Marker
            position={{ lat: currentMarker.lat, lng: currentMarker.lng }}
          />
        )}
        {coordinates && coordinates?.length > 0 && (
          <Polygon
            onClick={(location) => {
              setCurrentMarker(location.latLng.lat(), location.latLng.lng());
            }}
            ref={polygonRef}
            options={{
              fillColor: "rgba(217, 119, 6, 0.7)",
              editable: false,
            }}
            paths={coordinates}
          />
        )}
      </GoogleMap>
    );
  }
);

const SaleSpotCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [mapLoading, setMapLoading] = useState(true);
  const [selectedSaleAreaCoords, setSelectedSaleAreaCoords] = useState(null); // [lat, lng
  const [location, setLocation] = useState(null);
  const googleMapRef = useRef(null);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [center, setCenter] = useState(DefaultLocation);

  const handleChangeLocation = (lat, lng) => {
    setLocation({ lat: lat, lng: lng });
    setValue("latitude", lat);
    setValue("longitude", lng);
  };

  useEffect(() => {
    setTimeout(() => {
      setMapLoading(false);
    }, 500);
  }, []);

  const handleChangeZoom = (newZoom) => {
    setZoom(newZoom);
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      !id ? SaleSpotCreateValidations : SaleSpotUpdateValidations
    ),
    defaultValues: {
      is_active: true,
      category: "",
      showcases: [],
    },
  });

  const sale_area = useWatch({
    control,
    name: "sale_area",
    defaultValue: "",
  });

  const isActive = useWatch({
    control,
    name: "is_active",
    defaultValue: true,
  });

  const longitude = useWatch({
    control,
    name: "longitude",
    defaultValue: DefaultLocation.lng,
  });
  const latitude = useWatch({
    control,
    name: "latitude",
    defaultValue: DefaultLocation.lat,
  });

  const locationImage = useWatch({
    control,
    name: "location_image",
    defaultValue: "",
  });
  const mainSaleArea = useWatch({
    control,
    name: "main_sale_area",
    defaultValue: "",
  });

  const addSaleSpot = useMutation({
    mutationFn: (newSalespot) => services.createSaleSpot(newSalespot),
  });
  const updateSaleSpot = useMutation({
    mutationFn: (newSalespot) => services.updateSaleSpot(id, newSalespot),
  });

  const { isLoading: categoryLoading, data: categoryData } = useQuery({
    queryKey: ["categories"],
    queryFn: async () =>
      await services.saleSpotCategoryList(1, 400).then((res) => [
        ...res.data.data.map((item) => ({
          value: item._id,
          label: item.name,
        })),
      ]),
  });
  const { isLoading: showCaseLoading, data: showCaseData } = useQuery({
    queryKey: ["showCases"],
    queryFn: async () =>
      await services.showCaseList(1, 400).then((res) => [
        ...res.data.data.map((item) => ({
          value: item._id,
          label: item.name,
        })),
      ]),
  });
  const { isLoading: saleAreaLoading, data: saleAreaData } = useQuery({
    queryKey: ["saleAreas"],
    queryFn: async () =>
      await services.mainSalesAreaList(1, 400).then((res) => [
        ...res.data.data.map((item) => ({
          value: item._id,
          label: item.name,
          coordinates: item.coordinates,
        })),
      ]),
  });
  const { isLoading: subSaleAreaLoading, data: subSaleAreaData } = useQuery({
    queryKey: ["subSaleAreas", mainSaleArea],
    enabled: !!mainSaleArea,
    queryFn: async () =>
      await services
        .subSalesAreaCoordsListByMainId(mainSaleArea)
        .then((res) => [
          ...res.data.map((item) => ({
            value: item._id,
            label: item.name,
            coordinates: item.coordinates,
          })),
        ]),
  });

  const handleCancel = () => {
    reset();
    navigate("/saleSpot/list");
  };

  const onSubmit = async (data) => {
    let newSaleSpotPayload;
    let saleSpot;
    let formData = new FormData();

    const showCases = data?.showcases?.map((item) => item.value) || [];

    try {
      if (!(location?.lat && location?.lng)) {
        toast.error(t("notifications.error.salespot-location"), {
          duration: 3000,
        });
        return;
      }

      newSaleSpotPayload = {
        ...data,
        location: {
          lat: location?.lat.toString() || latitude.toString(),
          lng: location?.lng.toString() || longitude.toString(),
        },
        showcases: [...showCases],

        location_image: "",
      };
      if (
        data?.location_image instanceof File &&
        Object.values(errors).length === 0
      ) {
        formData.append("type", data.location_image?.type);
        formData.append("file", data.location_image);
        const photo = await services.fileUpload(formData);
        newSaleSpotPayload = {
          ...newSaleSpotPayload,
          location_image: photo?.data.file,
        };
      }

      if (!id) {
        newSaleSpotPayload = {
          ...newSaleSpotPayload,
          showcases: data.showcases.map((item) => {
            return {
              _id: item,
            };
          }),
        };

        saleSpot = await addSaleSpot.mutateAsync(newSaleSpotPayload);
      } else {
        saleSpot = await updateSaleSpot.mutateAsync(newSaleSpotPayload);
      }

      if (!id && saleSpot) {
        toast.success(
          saleSpot?.data?.message
            ? saleSpot?.data?.message
            : t("notifications.success.salespot-create"),
          {
            duration: 3000,
          }
        );

        handleCancel();
      } else if (id && saleSpot) {
        toast.success(
          saleSpot?.data?.message
            ? saleSpot?.data?.message
            : t("notifications.success.salespot-update"),
          {
            duration: 3000,
          }
        );
        handleCancel();
      }
    } catch (e) {
      toast?.error(
        e?.response?.data?.message || t("notifications.error.message"),
        {
          duration: 3000,
        }
      );
    }
  };

  const access_token = useWatch({
    control,
    name: "access_token",
    defaultValue: "",
  });

  const phone_number = useWatch({
    control,
    name: "phone_number",
    defaultValue: "",
  });

  const imei_second = useWatch({
    control,
    name: "imei_second",
    defaultValue: "",
  });

  const fetchSaleSpot = useCallback(async () => {
    if (id) {
      setLoading(true);

      try {
        const saleSpot = await services.getSaleSpotById(id);
        Object.keys(saleSpot.data).forEach((key) => {
          if (key === "location") {
            const location = saleSpot.data["location"];
            location.lat = parseFloat(location.lat);
            location.lng = parseFloat(location.lng);

            handleChangeLocation(location.lat, location.lng);
            setLocation({
              ...location,
            });
            setValue(key, saleSpot.data[key]);
          } else if (key === "showcases") {
            const showcases = saleSpot.data[key].map((item) => ({
              value: item._id,
              label: item.name,
            }));

            setValue("showcases", showcases);
          } else if (key === "main_sale_area") {
            setValue("main_sale_area", saleSpot.data[key]);
          } else {
            setValue(key, saleSpot.data[key]);
          }

          setLoading(false);
        });
      } catch (e) {
        navigate("/salespot");
        toast.error(t("notifications.error.salespot-notfound"), {
          duration: 3000,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchSaleSpot();
    }
  }, [id]);

  const getCenterOfAPolygon = (coordinates) => {
    if (coordinates) {
      var bounds = new window.google.maps.LatLngBounds();

      const newCoordinates = coordinates?.map((coord) => {
        return new window.google.maps.LatLng(
          parseFloat(coord.lat),
          parseFloat(coord.lng)
        );
      });

      for (let i = 0; i < newCoordinates.length; i++) {
        bounds.extend(newCoordinates[i]);
      }

      setCenter({
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng(),
      });
    }
  };

  const triggerSaleAreaChange = (value) => {
    const coordinates = subSaleAreaData?.find(
      (item) => item.value === value
    )?.coordinates;

    if (coordinates) {
      setSelectedSaleAreaCoords(coordinates);
      setTimeout(() => {
        getCenterOfAPolygon(coordinates);
      }, 400);
    } else {
      setSelectedSaleAreaCoords(null);
    }
  };

  useEffect(() => {
    if (sale_area && !subSaleAreaLoading) {
      triggerSaleAreaChange(sale_area);
    }
  }, [sale_area, subSaleAreaLoading]);

  return (
    <>
      {!categoryLoading && !loading ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <form
            onSubmit={handleSubmit((data) =>
              onSubmit(data, getValues("location_image"))
            )}
          >
            <div className="space-y-12">
              <div className="border-b border-white/10 pb-12">
                <div className="flex items-center justify-between">
                  <div>
                    <h2 className="text-base font-semibold leading-7 dark:text-white text-slate-700">
                      {id
                        ? t("titles.salespot-edit")
                        : t("titles.salespot-create")}
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-400">
                      {id
                        ? t("descriptions.salespot-edit")
                        : t("descriptions.salespot-create")}
                    </p>
                  </div>
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="first-name"
                      className="block text-xs font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("labels.is-active")}
                    </label>
                    <div className="mt-2">
                      <Switch
                        checked={isActive}
                        onChange={(event) =>
                          setValue("is_active", event.target.checked)
                        }
                        color="teal"
                        size="md"
                        thumbIcon={
                          isActive ? (
                            <IconCheck
                              style={{ width: rem(12), height: rem(12) }}
                              color="green"
                              stroke={3}
                            />
                          ) : (
                            <IconX
                              style={{ width: rem(12), height: rem(12) }}
                              color="red"
                              stroke={3}
                            />
                          )
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
                  <div className="sm:col-span-3">
                    <CustomInput
                      register={register}
                      name="name"
                      placeholder={t("salespot.name")}
                      errors={errors}
                      label={t("salespot.name")}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <span className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("salespot.category")}
                    </span>
                    <div className="mt-2">
                      <CustomSelect
                        control={control}
                        placeholder={t("placeholders.select")}
                        name="category"
                        data={categoryData}
                        searchable
                        clearable
                        height={"20px"}
                        error={errors.category?.message}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.category?.message)}
                      </p>
                    </div>
                  </div>
                  {!id && !showCaseLoading && (
                    <div className="sm:col-span-full">
                      <span className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                        {t("navigationItems.showcase")}
                      </span>
                      <div className="mt-2">
                        <CustomMultiSelect
                          control={control}
                          placeholder={t("placeholders.select")}
                          name="showcases"
                          data={showCaseData}
                          searchable
                          clearable
                          multiple={true}
                          value={getValues("showcases")}
                          height={"20px"}
                          error={errors.showcases?.message}
                        />
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="email-error"
                        >
                          {t(errors.showcases?.message)}
                        </p>
                      </div>
                    </div>
                  )}
                  <div className={"sm:col-span-3"}>
                    <div className="mt-2">
                      <CustomInput
                        name="phone_number"
                        id="phone_number"
                        placeholder={t("salespot.imei")}
                        autoComplete="phone_number"
                        value={phone_number}
                        onChange={(e) => {
                          setValue("phone_number", e.target.value);
                        }}
                        errors={errors}
                        label={t("salespot.imei")}
                        register={register}
                      />
                    </div>
                  </div>
                  <div className={"sm:col-span-3"}>
                    <div className="mt-2">
                      <CustomInput
                        name="imei_second"
                        id="imei_second"
                        placeholder={t("salespot.second-imei")}
                        autoComplete="imei_second"
                        value={imei_second}
                        onChange={(e) => {
                          setValue("imei_second", e.target.value);
                        }}
                        errors={errors}
                        label={t("salespot.second-imei")}
                        register={register}
                      />
                    </div>
                  </div>
                  <div className={"sm:col-span-3"}>
                    <span className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("navigationItems.sales")}
                    </span>
                    <div className="mt-2">
                      <CustomSelect
                        control={control}
                        placeholder={t("placeholders.select")}
                        name="main_sale_area"
                        onChange={(value) => {
                          setValue("main_sale_area", value);
                        }}
                        data={saleAreaData}
                        searchable
                        clearable
                        multiple={true}
                        height={"20px"}
                        error={errors.sale_area?.message}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.sale_area?.message)}
                      </p>
                    </div>
                  </div>

                  {mainSaleArea && subSaleAreaData?.length > 0 && (
                    <div className={"sm:col-span-3"}>
                      <span className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                        {t("navigationItems.subSales")}
                      </span>
                      <div className="mt-2">
                        <CustomSelect
                          control={control}
                          placeholder={t("placeholders.select")}
                          name="sale_area"
                          onChange={(value) => {
                            setValue("sale_area", value);
                          }}
                          data={subSaleAreaData}
                          searchable
                          clearable
                          multiple={true}
                          height={"20px"}
                          error={errors.sale_area?.message}
                        />
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="email-error"
                        >
                          {t(errors.sale_area?.message)}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("storage.long")}
                    </label>
                    <div className="mt-2">
                      <Tooltip label={t("descriptions.changeMap")}>
                        <Input
                          disabled={true}
                          value={longitude || location?.lng}
                          placeholder={t("storage.long")}
                          onChange={(event) => {
                            setValue("longitude", event.target.value);
                          }}
                          classNames={{
                            input: `dark:!bg-gray-800 !bg-gray-300/100   !ring-1 !border-none !rounded-md ring-inset ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                              errors.longitude?.message &&
                              "!ring-red-500 dark:!ring-red-500"
                            }}`,
                            wrapper:
                              "dark:!bg-gray-800 !bg-gray-300/100 rounded-md dark:!text-white !text-slate-700",
                            dropdown:
                              "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                            option:
                              "hover:dark:bg-gray-700 hover:bg-gray-200/50",
                          }}
                        />
                      </Tooltip>
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.longitude?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("storage.lat")}
                    </label>
                    <div className="mt-2">
                      <Tooltip label={t("descriptions.changeMap")}>
                        <Input
                          disabled={true}
                          value={latitude || location?.lat}
                          placeholder={t("storage.lat")}
                          onChange={(event) => {
                            setValue("latitude", event.target.value);
                          }}
                          classNames={{
                            input: `dark:!bg-gray-800 !bg-gray-300/100   !ring-1 !border-none !rounded-md ring-inset ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                              errors.latitude?.message &&
                              "!ring-red-500 dark:!ring-red-500"
                            }}`,
                            wrapper:
                              "dark:!bg-gray-800 !bg-gray-300/100 rounded-md dark:!text-white !text-slate-700",
                            dropdown:
                              "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                            option:
                              "hover:dark:bg-gray-700 hover:bg-gray-200/50",
                          }}
                        />
                      </Tooltip>
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.longitude?.message)}
                      </p>
                    </div>
                  </div>
                  {id && (
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="salespot-name"
                        className="text-sm flex items-center flex-row font-medium leading-6 dark:text-white text-slate-700"
                      >
                        Access Token
                        <Duplicator text={access_token} isTextVisible={false} />
                      </label>
                      <div className="mt-2">
                        <Input
                          {...register("access_token")}
                          type="text"
                          autoComplete="salespot-access-token"
                          placeholder={"Access Token"}
                          disabled={true}
                          classNames={{
                            input: `dark:!bg-gray-800 !bg-gray-300/100   !ring-1 !border-none !rounded-md ring-inset ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6
                                    }}`,
                            wrapper:
                              "dark:!bg-gray-800 !bg-gray-300/100 rounded-md dark:!text-white !text-slate-700",
                            dropdown:
                              "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                            option:
                              "hover:dark:bg-gray-700 hover:bg-gray-200/50",
                          }}
                        />
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="email-error"
                        >
                          {t(errors.name?.message)}
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("salespot.address-desc")}
                    </label>
                    <div className="mt-2">
                      <textarea
                        {...register("location_description")}
                        type="text"
                        autoComplete="location_description"
                        placeholder={t("salespot.address-desc")}
                        className={`block w-full rounded-md border-0 dark:bg-gray-800 bg-gray-300/70 py-1.5 dark:text-white text-slate-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-400/20 focus:ring-2 focus:ring-inset focus:ring-orange-500 !text-base md:!text-sm text-sm sm:leading-6
                        ${
                          errors.location_description?.message &&
                          "!ring-red-500 dark:!ring-red-500"
                        }
                        `}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.address_explanation?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="location-image"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("salespot.address-image")}
                    </label>
                    <Dropzone
                      onDrop={(files) => {
                        setValue("location_image", files[0]);
                      }}
                      onReject={(files) => console.log("rejected files", files)}
                      maxSize={3 * 1024 ** 2}
                      multiple={false}
                      classNames={{
                        root: "dark:!bg-gray-800 !bg-gray-300/70 dark:!text-white !text-slate-700 mt-2",
                      }}
                      accept={IMAGE_MIME_TYPE}
                    >
                      <Group
                        justify="center"
                        gap="xl"
                        mih={100}
                        style={{ pointerEvents: "none" }}
                      >
                        <Dropzone.Accept>
                          <IconPhoto
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: "var(--mantine-color-green-6)",
                            }}
                            stroke={1.5}
                          />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                          <IconX
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: "var(--mantine-color-red-6)",
                            }}
                            stroke={1.5}
                          />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                          {locationImage ? (
                            <div className="w-full h-full flex justify-center items-center">
                              <Image
                                radius="md"
                                h={"auto"}
                                className="max-h-[200px] w-full"
                                w="100%"
                                fit="cover"
                                src={
                                  locationImage instanceof File
                                    ? URL.createObjectURL(locationImage)
                                    : `${config.CDN_URL}${locationImage}`
                                }
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center">
                              <IconUpload
                                style={{
                                  width: rem(52),
                                  height: rem(52),
                                  color: "var(--mantine-color-gray-6)",
                                }}
                                stroke={1.5}
                              />
                              <Text
                                className="text-white"
                                style={{ fontSize: rem(12) }}
                              >
                                {t("placeholders.upload")}
                              </Text>
                            </div>
                          )}
                        </Dropzone.Idle>
                      </Group>
                    </Dropzone>
                  </div>
                  {mapLoading ? (
                    <BarLoading />
                  ) : (
                    selectedSaleAreaCoords?.length > 0 && (
                      <div className="sm:col-span-full">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                        >
                          {t("storage.map")}
                        </label>
                        <div className="mt-2">
                          <PolygonDrawer
                            currentMarker={{
                              lat: latitude || location?.lat,
                              lng: longitude || location?.lng,
                            }}
                            center={center}
                            setCurrentMarker={handleChangeLocation}
                            googleMapRef={googleMapRef}
                            coordinates={selectedSaleAreaCoords}
                            isActive={false}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                onClick={handleCancel}
                className="text-sm font-semibold leading-6 dark:text-white text-slate-700"
              >
                {t("actions.cancel")}
              </button>
              <SaveButton
                disabled={id ? updateSaleSpot.isLoading : addSaleSpot.isLoading}
              />
            </div>
          </form>
        </div>
      ) : (
        <BarLoading />
      )}
    </>
  );
};

export default SaleSpotCreate;
