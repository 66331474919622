import { useQuery } from "@tanstack/react-query";
import services from "../../services";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { useNavigate } from "react-router";
import { Badge, Button, Card, Group, Text } from "@mantine/core";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import TableSkeleton from "../../components/Skeleton/TableSkeleton";
import { compose, withProps, withStateHandlers } from "recompose";
import {
  GoogleMap,
  Polygon,
  withGoogleMap,
  withScriptjs,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import { IconInfoCircle } from "@tabler/icons-react";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import toast from "react-hot-toast";
import { ChevronOneUp } from "../../assets";
const defaultLocation = { lat: 41.015137, lng: 28.97953 };
const mapStyles = require("../../static/mapStyles.json");

const SaleSpotCard = ({ item, ...props }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card
      shadow="sm"
      padding="md"
      w={"100%"}
      radius="md"
      withBorder
      classNames={{
        root: `${
          props.checked ? "!ring-2  !ring-orange-500 dark:!ring-orange-700" : ""
        }  dark:!bg-gray-800 !bg-gray-300/30 dark:!ring-gray-700  dark:!text-white !w-full !border-none`,
      }}
    >
      <Group justify="space-between">
        <Badge color="#1E44FF" radius={"xs"}>
          <Text fw={600} size="xs">
            ID: {item._id}
          </Text>
        </Badge>
        <Text size="xs" c="dimmed" fw={600}>
          {item.location_description}
        </Text>
      </Group>
      <Group>
        <input
          type="checkbox"
          className=" top-1/2  h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500"
          value={props.checked}
          checked={props.checked}
          color={"#1E44FF"}
          onChange={props.onChange}
        />

        <Badge className="ml-auto" color={item.is_active ? "green" : "red"}>
          {item.is_active && !item.is_deleted
            ? t("status.active")
            : t("status.passive")}
        </Badge>
        <Button
          onClick={() => {
            navigate(`/salespot/transactions/${item._id}`);
          }}
          size="xs"
          color={"#1E44FF"}
        >
          İzle
        </Button>
      </Group>
    </Card>
  );
};

const iconReturner = (type) => {
  switch (type) {
    case "CRITICAL":
      return require("../../assets/ChevronThreeUp.svg").default;
    case "NORMAL":
      return require("../../assets/ChevronTwoUp.svg").default;
    case "FULL":
      return require("../../assets/ChevronOneUp.svg").default;
    default:
      return require("../../assets/ChevronOneUp.svg").default;
  }
};

const PolygonDrawer = compose(
  withStateHandlers(
    () => ({
      isOpen: false,
      openId: 0,
    }),
    {
      onToggleOpen:
        ({ isOpen }) =>
        (id) => ({
          isOpen: !isOpen,
          openId: id || 0,
        }),
    }
  ),
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing&key=AIzaSyCBM4uTkUhiTpZYBUFxR5NZ2ayD6S-PrmI",
    loadingElement: <div className="w-full" style={{ height: `100%` }} />,
    containerElement: <div className="w-full" style={{ height: `100%` }} />,
    mapElement: <div className="w-full" style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(({ center, zoom = 14, isActive = false, coordinates, ...props }) => {
  return (
    <GoogleMap
      defaultZoom={zoom}
      zoom={zoom}
      center={center || defaultLocation}
      defaultCenter={defaultLocation}
      defaultOptions={mapStyles}
    >
      {coordinates &&
        coordinates?.length > 0 &&
        coordinates.map((coord, i) => {
          if (coord.type !== "SPOT")
            return (
              <Polygon
                key={i}
                options={{
                  fillColor:
                    coord.type === "MAIN_AREA"
                      ? "rgba(0,0,0,0.4)"
                      : "rgba(217, 119, 6, 0.7)",
                  editable: false,
                }}
                paths={coord.coordinates}
              />
            );
        })}

      {coordinates &&
        coordinates.length > 0 &&
        coordinates.map((coord, i) => {
          if (coord.type === "SPOT")
            return (
              <Marker
                key={i}
                position={{
                  lat: parseFloat(coord.coordinates.lat),
                  lng: parseFloat(coord.coordinates.lng),
                }}
                icon={{
                  url: iconReturner(coord?.stock_status),
                  scaledSize: new window.google.maps.Size(65, 65),
                }}
                onClick={() => {
                  props.onToggleOpen(i);
                }}
              >
                {props.isOpen && props.openId === i && (
                  <InfoWindow onCloseClick={props.onToggleOpen}>
                    <div className="text-black">
                      <IconInfoCircle width={20} height={20} />
                      {coord.location_description}
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            );
        })}
    </GoogleMap>
  );
});

const SaleSpotManagement = () => {
  const { t } = useTranslation();
  const [mainSaleArea, setMainSaleArea] = useState();
  const [saleArea, setSaleArea] = useState("all");
  const [center, setCenter] = useState(defaultLocation);
  const [selectedSaleSpots, setSelectedSaleSpots] = useState([]);
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [zoom, setZoom] = useState(14);
  const [indeterminate, setIndeterminate] = useState(false);
  const [productRequestModalVisible, setProductRequestModalVisible] =
    useState(false);

  const getCenterOfAPolygon = (coordinates, zoom = 14) => {
    if (coordinates) {
      var bounds = new window.google.maps.LatLngBounds();

      const newCoordinates = coordinates?.map((coord) => {
        return new window.google.maps.LatLng(
          parseFloat(coord.lat),
          parseFloat(coord.lng)
        );
      });

      for (let i = 0; i < newCoordinates.length; i++) {
        bounds.extend(newCoordinates[i]);
      }

      setZoom(zoom);
      setCenter({
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng(),
      });
    }
  };

  const {
    isLoading: saleSpotLoading,
    error: saleSpotError,
    data: saleSpotData,
    refetch: refetchSaleSpot,
  } = useQuery({
    cacheTime: 0,
    enabled: !!mainSaleArea,
    queryKey: ["sale_spot_management_data", mainSaleArea, saleArea],
    queryFn: async () =>
      await services
        .getSaleSpotsByIds(mainSaleArea, saleArea || "all")
        .then((res) => {
          if (saleArea === "all") {
            getCenterOfAPolygon(res.data.main_sale_area.coordinates);
          } else {
            const currentSaleArea = res.data.sale_areas.find(
              (p) => p._id === saleArea
            );
            if (currentSaleArea) {
              getCenterOfAPolygon(currentSaleArea.coordinates, 16);
            }
          }

          return res.data;
        }),
  });

  const { isLoading: mainSaleLoading, data: mainSaleData } = useQuery({
    queryKey: ["mainSales"],
    queryFn: async () =>
      await services.mainSaleAreaSelectList().then((res) => res.data),
  });

  const { isLoading: subSaleLoading, data: subSaleData } = useQuery({
    queryKey: ["subSales", mainSaleArea],
    enabled: !!mainSaleArea,
    queryFn: async () =>
      await services
        .subSalesAreaListByMainId(mainSaleArea)
        .then((res) => [{ label: t("labels.all"), value: "all" }, ...res.data]),
  });

  const returnActiveCoordinates = () => {
    const coordinatesArray = [];

    if (saleSpotData) {
      coordinatesArray.push({
        coordinates: saleSpotData.main_sale_area.coordinates,
        type: "MAIN_AREA",
      });
    }

    if (saleSpotData && saleSpotData.sale_areas.length > 0) {
      saleSpotData.sale_areas.forEach((saleArea) => {
        coordinatesArray.push({
          coordinates: saleArea.coordinates,
          type: "SALE_AREA",
        });
      });
    }

    if (saleSpotData && saleSpotData.data.length > 0) {
      saleSpotData.data.forEach((spot) => {
        coordinatesArray.push({
          coordinates: spot.location,
          type: "SPOT",
          stock_status: spot?.stock_status || "CRITICAL", // [CRITICAL, NORMAL,FULL]
          location_description: spot.location_description || "",
        });
      });
    }

    return coordinatesArray;
  };

  const handleSelectChange = (e, saleSpot) => {
    setSelectedSaleSpots(
      e.target.checked
        ? [...selectedSaleSpots, saleSpot]
        : selectedSaleSpots.filter((p) => p !== saleSpot)
    );
  };

  const handleNavigate = (type) => {
    switch (type) {
      case "product-request":
        {
          setProductRequestModalVisible(true);
        }
        break;
      case "work-order":
        {
        }
        break;
    }
  };

  const handleConfirm = async () => {
    const createOrderPayload = {
      sale_spots: selectedSaleSpots.map((p) => p._id),
      main_sale_area: mainSaleArea,
    };
    try {
      const response = await services.createOrder(createOrderPayload);
      if (response.success) {
        toast.success(
          response?.data?.message
            ? response?.data?.message
            : t("notifications.success.send-product-request"),
          {
            duration: 3000,
          }
        );
      }
    } catch (e) {
      toast.error(
        e?.response?.data?.message
          ? e?.response?.data?.message
          : t("notifications.error.message"),
        {
          duration: 3000,
        }
      );
    }
    setProductRequestModalVisible(false);
  };
  const handleSelectAll = () => {
    setSelectedSaleSpots(checked || indeterminate ? [] : saleSpotData?.data);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  useEffect(() => {
    const isIndeterminate =
      selectedSaleSpots.length > 0 &&
      selectedSaleSpots.length < saleSpotData?.data?.length;
    setChecked(selectedSaleSpots.length === saleSpotData?.data?.length);
    setIndeterminate(isIndeterminate);
    if (checkbox.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedSaleSpots, saleSpotData]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 w-screen h-screen md:max-h-[85%] max-h-full max-w-full">
      <ConfirmModal
        title={t("labels.product-request")}
        isOpen={productRequestModalVisible}
        confirmText={t("descriptions.product-request")}
        onClose={() => setProductRequestModalVisible(false)}
        onConfirm={handleConfirm}
      />
      <div className="col-span-1 w-full  h-full min-h-[300px]">
        <PolygonDrawer
          center={center}
          zoom={zoom}
          coordinates={returnActiveCoordinates()}
        />
      </div>
      <div className="col-span-1 w-full h-full flex flex-col px-4 md:px-12 pt-6 md:py-2 gap-y-4">
        <CustomSelect
          withoutController
          data={mainSaleData}
          value={mainSaleArea}
          placeholder={t("placeholders.select")}
          onChange={(value) => {
            setMainSaleArea(value);
            setSaleArea("all");
          }}
        />
        {mainSaleArea && (
          <CustomSelect
            withoutController
            value={saleArea}
            placeholder={t("placeholders.select")}
            onChange={(value) => {
              setSaleArea(value);
              setChecked(false);
              setSelectedSaleSpots([]);
            }}
            data={subSaleData}
          />
        )}
        <div className="py-2 flex-row flex gap-4 justify-end">
          <Button
            color="#1E44FF"
            disabled={selectedSaleSpots.length === 0}
            onClick={() => handleNavigate("product-request")}
            radius={"xs"}
          >
            {t("actions.create-product-request")}
          </Button>
          <Button
            color="#1E44FF"
            disabled={selectedSaleSpots.length === 0}
            onClick={() => handleNavigate("work-order")}
            radius={"xs"}
          >
            {t("actions.create-work-order")}
          </Button>
        </div>

        {!saleSpotLoading && saleSpotData?.data && (
          <div className="mt-2 flow-root">
            <div className="-mx-4 -my-2 overflow-y-scroll overflow-x-visible  sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full  align-middle sm:px-6 max-h-[550px]  min-h-[550px] w-full">
                <div className="flex flex-row justify-end items-center gap-4 mb-2 px-2 py-2">
                  <label className="leading-3 font-[600]" htmlFor="all">
                    <Text
                      size="md"
                      className="dark:!text-white !text-slate-700"
                    >
                      {t("labels.all")}
                    </Text>
                  </label>
                  <input
                    id="all"
                    type="checkbox"
                    className=" top-1/2  h-5 w-5 rounded border-gray-300 text-orange-500 focus:ring-orange-500"
                    ref={checkbox}
                    checked={checked}
                    onChange={handleSelectAll}
                  />
                </div>

                <div className="flex flex-1 gap-2 px-2 flex-col">
                  {!saleSpotLoading &&
                    saleSpotData?.data &&
                    saleSpotData.data.map((saleSpot, index) => (
                      <SaleSpotCard
                        key={"saleSpotCard" + index}
                        item={saleSpot}
                        checked={selectedSaleSpots.includes(saleSpot)}
                        onChange={(e) => handleSelectChange(e, saleSpot)}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SaleSpotManagement;
