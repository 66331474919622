import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import services from "../../../services";
import { Button, Pagination } from "@mantine/core";
import { useEffect, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomSearch from "../../../components/CustomSearch/CustomSearch";
import toast from "react-hot-toast";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { FaCheck, FaPencilAlt } from "react-icons/fa";

const PurchaseSaleList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1
  );
  const [localData, setLocalData] = useState([]);
  const [editableFields, setEditableFields] = useState({});
  const [hasChanges, setHasChanges] = useState(false);

  const [pageSize, setPageSize] = useState(
    searchParams.get("pageSize") ? searchParams.get("pageSize") : 10
  );
  const [search, setSearch] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );

  const {
    isLoading,
    error,
    data,
    isFetching,
    refetch: refetchPurchaseSale,
  } = useQuery({
    queryKey: ["productPurchaseSale", currentPage, pageSize, search],
    enabled: !!search || (!!pageSize && !!currentPage),
    queryFn: async () =>
      await services
        .getPurchaseSaleList(currentPage, pageSize, search)
        .then((res) => res?.data),
  });
  useLayoutEffect(() => {
    const search = searchParams.get("search");
    if (search) {
      setSearchParams({ page: 1, pageSize: pageSize, search: search });
    } else {
      // setSearchParams({ page: , pageSize: pageSize });
    }
  }, [currentPage, pageSize, setSearchParams, search, searchParams]);

  useEffect(() => {
    if (data?.data?.length === 0 && currentPage > 1) {
      setSearchParams({ page: 1, pageSize: pageSize });
    }
  }, [data, currentPage, pageSize, setSearchParams]);

  useEffect(() => {
    if (data?.data) {
      setLocalData(data.data);
      // Initialize all editable fields as false
      const initialEditable = data.data.reduce(
        (acc, item) => ({
          ...acc,
          [item._id]: {
            purchase_price: false,
            price: false,
          },
        }),
        {}
      );
      setEditableFields(initialEditable);
    }
  }, [data]);
  if (error) return <p>Error :(</p>;

  const handleDelete = async (id) => {
    await services.productPurchaseSaleDelete(id).then((res) => {
      if (res.success) {
        toast.success(
          res?.message
            ? res.message
            : t("notifications.success.product-sub-purchase_sale-delete")
        );

        setDeleteModalVisible(false);
        refetchPurchaseSale();
      } else {
        toast.error(
          res?.message ? res.message : t("notifications.error.message")
        );
      }
    });
  };

  const handleNavigate = (page, id) => {
    switch (page) {
      case "create":
        navigate("/product/sub-purchase_sale/create");
        break;
      case "edit":
        navigate("/product/sub-purchase_sale/edit/" + id);
        break;
      case "delete":
        setId(id);
        setDeleteModalVisible(true);
        break;
      default:
        navigate("/product/sub-purchase_sale/list");
    }
  };
  const handlePageChange = (page) => {
    const search = searchParams.get("search");
    if (data?.data?.length === 0 && page > 1) {
      setCurrentPage(page - 1);
    }

    setCurrentPage(page);
    if (search) {
      setSearchParams({ page: page, pageSize: pageSize, search: search });
      return;
    } else {
      setSearchParams({ page: page, pageSize: pageSize });
      return;
    }
  };
  const handleSearch = (value) => {
    setSearch(value);
    setSearchParams({ page: 1, pageSize: pageSize, search: value });
  };

  const toggleEdit = (id, field) => {
    setEditableFields((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: !prev[id]?.[field],
      },
    }));
  };

  const handlePriceChange = (id, field, value) => {
    setLocalData((prev) =>
      prev.map((item) =>
        item._id === id ? { ...item, [field]: Number(value) } : item
      )
    );
    setHasChanges(true);
  };

  const handleSaveAll = async () => {
    try {
      const changedItems = localData.filter((item) => {
        const originalItem = data.data.find((d) => d._id === item._id);
        return (
          originalItem.purchase_price !== item.purchase_price ||
          originalItem.price !== item.price
        );
      });

      await Promise.all(
        changedItems.map((item) =>
          services.updatePrices(item._id, {
            purchase_price: item.purchase_price,
            price: item.price,
          })
        )
      );

      toast.success(t("notifications.success.prices-updated"));
      refetchPurchaseSale();
      setHasChanges(false);
      setEditableFields((prev) =>
        Object.keys(prev).reduce(
          (acc, key) => ({
            ...acc,
            [key]: { purchase_price: false, price: false },
          }),
          {}
        )
      );
    } catch (error) {
      toast.error(t("notifications.error.update-failed"));
    }
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmModal
        isOpen={deleteModalVisible}
        onConfirm={() => handleDelete(id)}
        onClose={() => {
          setDeleteModalVisible(false);
          setId(null);
        }}
        title={t("titles.delete-sub-purchase_sale")}
        confirmText={t("descriptions.delete-sub-purchase_sale")}
      />
      {hasChanges && (
        <div className="flex justify-end mb-4">
          <Button
            type="button"
            size="xs"
            radius={"md"}
            color={"blue"}
            className="rounded-md dark:bg-orange-700 !bg-orange px-3 py-2 text-sm font-semibold text-white shadow-sm dark:hover:!bg-orange-600 hover:!bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
            onClick={handleSaveAll}
          >
            {t("actions.save")}
          </Button>
        </div>
      )}

      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 dark:text-white text-slate-700">
            {t("titles.purchase-sale-list")}
          </h1>
          <p className="mt-2 text-sm dark:text-gray-400 text-gray-500">
            {t("descriptions.purchase-sale-list")}
          </p>
        </div>

        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex flex-col sm:flex-row xs:flex-col-reverse gap-2 xs:justify-between justify-start items-center">
          <div className="block md:hidden xs:w-full">
            <CustomSearch
              textInputClassName="xs:w-full"
              search={search}
              onSearch={handleSearch}
            />
          </div>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="dark:bg-gray-800 bg-gray-300 text-slate-700">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-gray-300 text-slate-700 sm:pl-6"
                    >
                      {t("product.name")}
                    </th>

                    <th
                      scope="col"
                      className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      {t("product.purchase-price")}
                    </th>
                    <th
                      scope="col"
                      className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      {t("product.old-sale-price")}
                    </th>
                    <th
                      scope="col"
                      className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      {t("product.current-sale-price")}
                    </th>

                    <th
                      scope="col"
                      className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      {t("product-report.vat-rate")}
                    </th>

                    <th
                      scope="col"
                      className="flex py-3.5 pl-3 pr-4 sm:pr-6 w-full "
                    >
                      <div className="hidden md:block">
                        <CustomSearch search={search} onSearch={handleSearch} />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading || isFetching ? (
                    <TableSkeleton colSize={7} />
                  ) : (
                    localData.map((item, index) => (
                      <tr
                        className={`${
                          index % 2 === 0
                            ? "dark:bg-slate-700/80 bg-gray-200"
                            : "dark:bg-slate-600/80 bg-gray-100"
                        } hover:bg-orange-200/90 dark:hover:bg-orange-600/20`}
                        key={item._id}
                      >
                        <td className="whitespace-nowrap px-5 py-4 text-sm dark:text-gray-300 text-gray-500">
                          {item.name}
                        </td>
                        <td className="whitespace-nowrap px-5 py-4 text-sm dark:text-gray-300 text-gray-500">
                          <span
                            contentEditable={
                              editableFields[item._id]?.purchase_price
                            }
                            onBlur={(e) =>
                              handlePriceChange(
                                item._id,
                                "purchase_price",
                                e.target.textContent
                              )
                            }
                            suppressContentEditableWarning={true}
                            className={`outline-none px-2 rounded ${
                              editableFields[item._id]?.purchase_price
                                ? "border-b border-orange-500"
                                : ""
                            }`}
                          >
                            {item.purchase_price || 0}
                          </span>
                          <button
                            onClick={() =>
                              toggleEdit(item._id, "purchase_price")
                            }
                          >
                            {editableFields[item._id]?.purchase_price ? (
                              <FaCheck
                                className="text-green-600 hover:text-green-900 ml-4"
                                size={12}
                              />
                            ) : (
                              <FaPencilAlt
                                className="text-orange-600 hover:text-orange-900 ml-4"
                                size={12}
                              />
                            )}
                          </button>
                        </td>
                        <td className="whitespace-nowrap px-5 py-4 text-sm dark:text-gray-300 text-gray-500">
                          {item.old_price}
                        </td>

                        <td className="whitespace-nowrap px-5 py-4 text-sm dark:text-gray-300 text-gray-500">
                          <span
                            contentEditable={editableFields[item._id]?.price}
                            onBlur={(e) =>
                              handlePriceChange(
                                item._id,
                                "price",
                                e.target.textContent
                              )
                            }
                            suppressContentEditableWarning={true}
                            className={`outline-none px-2 rounded ${
                              editableFields[item._id]?.price
                                ? "border-b border-orange-500"
                                : ""
                            }`}
                          >
                            {item.price || 0}
                          </span>
                          <button onClick={() => toggleEdit(item._id, "price")}>
                            {editableFields[item._id]?.price ? (
                              <FaCheck
                                className="text-green-600 hover:text-green-900 ml-4"
                                size={12}
                              />
                            ) : (
                              <FaPencilAlt
                                className="text-orange-600 hover:text-orange-900 ml-4"
                                size={12}
                              />
                            )}
                          </button>
                        </td>
                        <td className="whitespace-nowrap px-5 py-4 text-sm dark:text-gray-300 text-gray-500">
                          {item.kdv ? item.kdv + "%" : "0%"}
                        </td>
                        <td className="whitespace-nowrap px-5 py-4 text-sm dark:text-gray-300 text-gray-500"></td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-5  overflow-x-auto w-full  sm:gap-1  sm:flex-row items-center  my-5">
          <div className="flex justify-center w-full items-center">
            <Pagination
              total={data?.totalPageCount}
              siblings={1}
              size={"md"}
              defaultValue={
                Number(searchParams.get("page"))
                  ? Number(searchParams.get("page"))
                  : 1
              }
              value={currentPage}
              onChange={handlePageChange}
              color="orange"
              classNames={{
                control:
                  "flex items-center justify-center gap-x-2 dark:disabled:!bg-gray-700/50  dark:[&[data-active=true]]:!bg-orange-700/50 dark:[&[data-active=true]]:!border-orange-700  dark:[&[data-active=true]]:!border-orange-700 [&[data-active=true]]:!bg-orange-600 dark:bg-orange-800/90 [&[data-active=true]]:!border-orange-300/90 !bg-gray-200/90 !border   dark:!bg-slate-800 dark:!text-white  :disabled:!bg-gray-100/50 !rounded-lg ",
              }}
            />
          </div>
          <div className=" flex justify-end w-[80px]">
            <CustomSelect
              withoutController={true}
              placeholder={t("placeholders.select")}
              onChange={(value) => {
                setPageSize(value);
                setSearchParams({ page: 1, pageSize: value || 10 });
                setCurrentPage(1);
              }}
              data={[5, 10, 20, 30, 50]}
              value={pageSize}
              name="pageSize"
              searchable={false}
              height={"20px"}
              width={20}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseSaleList;
