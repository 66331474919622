import { axiosInstance } from ".";

export const reportService = (state) => {
  return {
    async report(start_date, end_date, sale_spot, user) {
      const params = new URLSearchParams();
      params.append("start_date", start_date);
      params.append("end_date", end_date);
      if (sale_spot) params.append("sale_spot", sale_spot);
      if (user) params.append("user", user);
      const response = await axiosInstance.get(
        `report/product${params.toString() ? `?${params.toString()}` : ""}`
      );
      return response?.data || [];
    },
  };
};
